import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { updateLocation } from '../../util/api';

// ================ Action types ================ //

export const UPDATE_LOCATION_REQUEST = 'app/ContactDetailsPage/UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'app/ContactDetailsPage/UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'app/ContactDetailsPage/UPDATE_LOCATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateLocation:null,
  updateLocationInProgress:false,
  updateLocationError:null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
      case UPDATE_LOCATION_REQUEST:
        return {
          ...state,
          updateLocationInProgress: true,
          updateLocationError: null,
          updateLocation: false,
        };
      case UPDATE_LOCATION_SUCCESS:
        return {
          ...state,
          updateLocation: payload,
          updateLocationInProgress: false,
        };
      case UPDATE_LOCATION_ERROR:
        return {
          ...state,
          updateLocationInProgress: false,
          updateLocationError: payload,
          updateLocation: false,
        };  

    default:
      return state;
  }
}

// ================ Action creators ================ //



export const locationUpdateRequest = () => ({ type: UPDATE_LOCATION_REQUEST });

export const locationUpdateSuccess = (response) => ({ 
  type: UPDATE_LOCATION_SUCCESS ,
  payload:response
});

export const locationUpdateError = e => ({
  type: UPDATE_LOCATION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Make a phone number update request to the API and return the current user.
 */

export const updateUserAccount = params => async (dispatch, getState, sdk) => {
    const {availabilityVendor,country, distance,location } = params
    const currentUser = getState().user.currentUser;
    
  dispatch(locationUpdateRequest());
  try {
    return await updateLocation({
      availabilityVendor:availabilityVendor,
      country:country,
      distance:distance,
      location:location,
      currentUser:currentUser
    }).then(response =>{
      dispatch(locationUpdateSuccess(response));
      return response
    });
  } catch (error) {
    dispatch(locationUpdateError(storableError(error)));
  }
};

export const loadData = () => {
    // Since verify email happens in separate tab, current user's data might be updated
    return fetchCurrentUser();
  };
