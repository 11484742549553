export const getUserRole = (user) => {
    return user && user?.id && user?.attributes?.profile?.publicData?.userRole
}

export const getListingType = (listing) => {
    return listing && listing?.id && listing?.attributes?.publicData?.listingType
}

export const getVendorListingId = (user) => {
    return user.id && user?.attributes?.profile?.publicData?.userListingId
}  