/**
 * Transaction process graph for product orders:
 *   - session-course
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  INQUIRE: 'transition/inquire-booking',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
  };
  
  export const states = {
    INITIAL: 'initial',
    COMPLETED: 'completed',
    REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
    REVIEWED: 'reviewed',
  };
  
  /**
   * Description of transaction process graph
   *
   * You should keep this in sync with transaction process defined in Marketplace API
   *
   * Note: we don't use yet any state machine library,
   *       but this description format is following Xstate (FSM library)
   *       https://xstate.js.org/docs/
   */
  export const graph = {
    // id is defined only to support Xstate format.
    // However if you have multiple transaction processes defined,
    // it is best to keep them in sync with transaction process aliases.
    id: 'review-proces/release-1',
  
    // This 'initial' state is a starting point for new transaction
    initial: states.INITIAL,
  
    // States
    states: {
      [states.INITIAL]: {
        on: {
          [transitions.INQUIRE]: states.COMPLETED,
        },
      },
      [states.COMPLETED]: {
        on: {
          [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
          [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED,
        },
      },
      [states.REVIEWED]: { type: 'final' },
    },
  };
  
  // Check if a transition is the kind that should be rendered
  // when showing transition history (e.g. ActivityFeed)
  // The first transition and most of the expiration transitions made by system are not relevant
  export const isRelevantPastTransition = transition => {
    return [
     
      transitions.INQUIRE,
      transitions.REVIEW_1_BY_CUSTOMER,
      transitions.EXPIRE_REVIEW_PERIOD,
    ].includes(transition);
  };

  export const isCustomerReview = transition => {
    return [transitions.REVIEW_1_BY_CUSTOMER].includes(transition);
  };

  export const isProviderReview = transition => {
     return false;
  };
  
  // Check if the given transition is privileged.
  //
  // Privileged transitions need to be handled from a secure context,
  // i.e. the backend. This helper is used to check if the transition
  // should go through the local API endpoints, or if using JS SDK is
  // enough.
  export const isPrivileged = transition => {
    return false;
  };
  
  // Check when transaction is completed (item is received and review notifications sent)
  export const isCompleted = transition => {
    const txCompletedTransitions = [
      transitions.INQUIRE,
      transitions.REVIEW_1_BY_CUSTOMER,
      transitions.EXPIRE_REVIEW_PERIOD,
    ];
    return txCompletedTransitions.includes(transition);
  };

  export const isRefunded = transition => {
    return false;
  };
  
export const statesNeedingProviderAttention = [states.COMPLETED];
  