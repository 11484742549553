import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import {
  HIRE_FIXED,
  HIRE_FIXED_CREDIT,
  PLANNING_TO_HIRE,
  PLANNING_TO_HIRE_CREDIT,
  R_NOT_SURE,
  R_NOT_SURE_CREDIT,
  propTypes,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  H6,
  IconCard,
  Modal,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';
import { sendInquiry } from '../../containers/ListingPage/ListingPage.duck';
import InquiryForm from '../../containers/ListingPage/InquiryForm/InquiryForm';
import { handleSubmitInquiry } from '../../containers/ListingPage/ListingPage.shared';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { contactUsMessage } from '../../util/api';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    onSendInquiry,
    getListing,
    sendInquiryInProgress,
    sendInquiryError,
    transactions,
    currentUser,
    onCustomerListingView,
    customerId,
  } = props;

  const getQueryParam = (search, key) => {
    const params = new URLSearchParams(search.slice(1).replace(/,/g, '&'));
    return params.get(key);
  };
  const customerListingId = getQueryParam(customerId.search, 'listingId');

  const [isInquiryModalOpen, setInquiryModal] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const isVendorLsting = currentListing && currentListing;
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const routes = routeConfiguration;

  const id = currentListing.id.uuid;
  const { title = '', price, publicData, description } = currentListing.attributes;

  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const {
    companyName,
    category,
    ratings,
    totalRatings,
    email,
    isPhoneNumberVerified,
    subscriptionStatus,
  } = publicData;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
  const enquireListingIds = transactions?.filter(
    item => item?.relationships?.listing?.data?.id?.uuid === currentListing.id.uuid
  );
  const alreadyTxId = enquireListingIds?.find(tx => tx?.id?.uuid);
  const commonParams = { params: { id: currentListing.id.uuid }, history, routes, alreadyTxId };

  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen: setInquiryModal,
  });

  const {
    location,
    country,
    eventBudget,
    eventHiringDecision,
    eventTypePlanning,
    serviceType,
    date,
  } = currentUser?.attributes?.profile?.publicData || '';

  const userLocation = location?.search ? location?.search : country || '';
  const requiredCredit =
    eventHiringDecision && eventHiringDecision == R_NOT_SURE
      ? R_NOT_SURE_CREDIT
      : eventHiringDecision && eventHiringDecision == PLANNING_TO_HIRE
      ? PLANNING_TO_HIRE_CREDIT
      : eventHiringDecision && eventHiringDecision == HIRE_FIXED
      ? HIRE_FIXED_CREDIT
      : 10 || '';
  const { firstName, lastName } = currentUser?.attributes?.profile || '';
  const vendorName = firstName + ' ' + lastName || '';

  const emailCategory =
    serviceType
      ?.filter(item => category?.includes(item?.label))
      ?.map(filteredItem => filteredItem.label) || [];

  return (
    <div className={classes}>
      <div className={css.imageWrapper}>
        {subscriptionStatus == 'active' ? (
          <span className={css.proBadge}>
            <IconCard brand="star" />
            Pro star
          </span>
        ) : null}
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.headingRow}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <div className={css.verifiedMobile}>
              {isPhoneNumberVerified == true ? (
                <span className={css.verified}>Verified</span>
              ) : (
                <span className={css.notVerified}>Not Verfied</span>
              )}
            </div>
          </div>
          <div className={css.category}>{category?.join(' ,' + ' ')}</div>
          <div className={css.description}>{description}</div>
          <div>
            {ratings
              ? Array(+ratings)
                  .fill(',')
                  .map(st => <IconCard brand="star" />)
              : null}
            <p>({totalRatings ? totalRatings : 0} Reviews)</p>
          </div>
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          <div className={css.bottomButtons}>
            <div
              className={css.viewListing}
              onClick={async () => {
                await contactUsMessage({
                  vendorName: vendorName,
                  emailCategory: emailCategory,
                  eventBudget: eventBudget,
                  eventHiringDecision: eventHiringDecision,
                  eventTypePlanning: eventTypePlanning,
                  userLocation: userLocation,
                  customerListingId: customerListingId,
                  eventDate: date ? date : "I'm not sure yet",
                  requiredCredit: requiredCredit,
                  customerId: email,
                  senderId: 'info@myfindor.com',
                  subject: `${vendorName} would like to contact them`,
                  text: 'text',
                  customerViewListing: 'customerViewListing',
                });
                history.push(
                  createResourceLocatorString('ListingPage', routes, { id: id, slug: slug }, {})
                );
              }}
            >
              View Listing
            </div>
            <div className={css.callbackButton}>
              {currentListing && currentListing.id.uuid ? (
                <div className={css.messageButton} onClick={() => [setInquiryModal(true)]}>
                  Request callback
                </div>
              ) : null}
            </div>
          </div>
          {isInquiryModalOpen ? (
            <Modal
              id="ListingPage.inquiry"
              contentClassName={css.inquiryModalContent}
              isOpen={isInquiryModalOpen}
              onClose={() => [setInquiryModal(false)]}
              usePortal
              onManageDisableScrolling={() => {}}
            >
              <InquiryForm
                className={css.inquiryForm}
                submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorName}
                sendInquiryError={sendInquiryError}
                onSubmit={onSubmitInquiry}
                inProgress={sendInquiryInProgress}
                alreadyTxId={alreadyTxId}
              />
            </Modal>
          ) : null}
        </div>
      </div>

      <div className={css.requestCallBackBtn}>
        {isPhoneNumberVerified == true ? (
          <span className={css.verified}>Verified</span>
        ) : (
          <span className={css.notVerified}>Not Verfied</span>
        )}
        {currentListing && currentListing.id.uuid ? (
          <div className={css.messageButton} onClick={() => [setInquiryModal(true)]}>
            Request callback
          </div>
        ) : null}
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
