import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { getStrapiHeroVidoes } from '../../util/api';
import { PRIVACY_POLICY_ASSET_NAME } from '../PrivacyPolicyPage/PrivacyPolicyPage';
import { TOS_ASSET_NAME } from '../TermsOfServicePage/TermsOfServicePage';

export const GET_STRAPI_HERO_VEDIO_REQUEST = 'app/LandingPage/GET_STRAPI_HERO_VEDIO_REQUEST';
export const GET_STRAPI_HERO_VEDIO_SUCCESS = 'app/LandingPage/GET_STRAPI_HERO_VEDIO_SUCCESS';
export const GET_STRAPI_HERO_VEDIO_ERROR = 'app/LandingPage/GET_STRAPI_HERO_VEDIO_ERROR';

export const ASSET_NAME = 'landing-page';

// ================ Reducer ================ //

const initialState = {
  getHeroVedioData: null,
  getHeroVedioError: null,
  getHeroVedioInProgress: false,

};

let listings = [];

const resultIds = data => data.data.map(l => l.id);

const LandingPage = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STRAPI_HERO_VEDIO_REQUEST:
      return { ...state, getHeroVedioInProgress: true, getHeroVedioError: null };
    case GET_STRAPI_HERO_VEDIO_SUCCESS:
      return { ...state, getHeroVedioInProgress: false, getHeroVedioData: payload };
    case GET_STRAPI_HERO_VEDIO_ERROR:
      return { ...state, getHeroVedioInProgress: false, getHeroVedioError: payload };
    default:
      return state;
  }
};

export default LandingPage;


// ================ Action creators ================ //

const getStrapiRequest = () => ({ type: GET_STRAPI_HERO_VEDIO_REQUEST });
const getStrapiSucess = resp => ({ type: GET_STRAPI_HERO_VEDIO_SUCCESS, payload: resp });
const getStrapiError = e => ({ type: GET_STRAPI_HERO_VEDIO_ERROR, error: true, payload: e });

export const loadData = (params, search) => dispatch => {
  const pageAsset = { 
    landingPage: `content/pages/${ASSET_NAME}.json`,
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,                  
   };
  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, true)),
    // dispatch( strapiHeroData())
  ]);
};
