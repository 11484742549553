//Action types

import { isScrollingDisabled } from '../../ducks/ui.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  createSubscriptionWithStripe,
  getPriceFromStripe,
  getSubscriptionsFromStripe,
  getVendorSubscriptionStatus,
} from '../../util/api';
import { storableError } from '../../util/errors';

export const FETCH_SUBSCRIPTION_PRICE_REQUEST = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_REQUEST';
export const FETCH_SUBSCRIPTION_PRICE_SUCCESS = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_SUCCESS';
export const FETCH_SUBSCRIPTION_PRICE_ERROR = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTION_PRICE_ERROR';

export const FETCH_SUBSCRIPTIONS_REQUEST = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_ERROR';

export const FETCH_SUBSCRIPTIONS_DATA_REQUEST = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_DATA_REQUEST';
export const FETCH_SUBSCRIPTIONS_DATA_SUCCESS = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_DATA_SUCCESS';
export const FETCH_SUBSCRIPTIONS_DATA_ERROR = 'app/StripeSubscriptionPage/FETCH_SUBSCRIPTIONS_DATA_ERROR';

export const CREATE_SUBSCRIPTIONS_REQUEST = 'app/StripeSubscriptionPage/CREATE_SUBSCRIPTIONS_REQUEST';
export const CREATE_SUBSCRIPTIONS_SUCCESS = 'app/StripeSubscriptionPage/CREATE_SUBSCRIPTIONS_SUCCESS';
export const CREATE_SUBSCRIPTIONS_ERROR = 'app/StripeSubscriptionPage/CREATE_SUBSCRIPTIONS_ERROR';

export const FETCH_LISTING_REQUEST = 'app/StripeSubscriptionPage/FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/StripeSubscriptionPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/StripeSubscriptionPage/FETCH_LISTING_ERROR';
//InitialState
const initialState = {
  subscriptionPlan: null,
  fetchPriceInProgress: false,
  fetchPriceError: null,
  userSubscriptions: [],
  fetchSubscriptionInProgress: false,
  fetchSubscriptionError: null,
  createSubscriptionInProgress:false,
  createSubscriptionError:null,
  subscriptionCreate:[],

  getStatusData:[],
  getStatusDataInProgress:false,
  getStatusDataError:null,

  fetchListing:[],
  fetchListingInProgress:false,
  fetchListingError:null
};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUBSCRIPTION_PRICE_REQUEST:
      return { ...state, fetchPriceInProgress: true, fetchPriceError: null };
    case FETCH_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        subscriptionPlan: payload,
        fetchPriceInProgress: false,
        fetchPriceError: null,
      };
    case FETCH_SUBSCRIPTION_PRICE_ERROR:
      return { ...state, fetchPriceInProgress: false, fetchPriceError: payload };
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return { ...state, fetchSubscriptionInProgress: true, fetchSubscriptionError: null };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        userSubscriptions: payload,
        fetchSubscriptionInProgress: false,
        fetchSubscriptionError: null,
      };
    case FETCH_SUBSCRIPTIONS_ERROR:
      return { ...state, fetchSubscriptionInProgress: false, fetchSubscriptionError: payload };

      case FETCH_SUBSCRIPTIONS_REQUEST:
        return { ...state, fetchSubscriptionInProgress: true, fetchSubscriptionError: null };
      case FETCH_SUBSCRIPTIONS_SUCCESS:
        return {
          ...state,
          userSubscriptions: payload,
          fetchSubscriptionInProgress: false,
          fetchSubscriptionError: null,
        };
      case FETCH_SUBSCRIPTIONS_ERROR:
        return { ...state, fetchSubscriptionInProgress: false, fetchSubscriptionError: payload };  

        case CREATE_SUBSCRIPTIONS_REQUEST:
          return { ...state, createSubscriptionInProgress: payload, createSubscriptionError: null };
        case CREATE_SUBSCRIPTIONS_SUCCESS:
          return {
            ...state,
            subscriptionCreate: payload,
            createSubscriptionInProgress: false,
            createSubscriptionError: null,
          };
        case CREATE_SUBSCRIPTIONS_ERROR:
          return { ...state, createSubscriptionInProgress: false, createSubscriptionError: payload };

        case FETCH_SUBSCRIPTIONS_DATA_REQUEST:
          return { ...state, getStatusDataInProgress: payload, getStatusDataError: null };
        case FETCH_SUBSCRIPTIONS_DATA_SUCCESS:
          return {
            ...state,
            getStatusData: payload.data.data,
            getStatusDataInProgress: false,
            getStatusDataError: null,
          };
        case FETCH_SUBSCRIPTIONS_DATA_ERROR:
          return { ...state, fetchListingInProgress: false, getStatusDataError: payload };

        case FETCH_LISTING_REQUEST:
          return { ...state, fetchListingInProgress: payload, fetchListingError: null };
        case FETCH_LISTING_SUCCESS:
          
          return {
            ...state,
            fetchListing: payload.data.data,
            getStatusDataInProgress: false,
            fetchListingError: null,
          };
        case FETCH_LISTING_ERROR:
          return { ...state, fetchListingInProgress: false, fetchListingError: payload };
          
          

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchPriceRequest = () => ({
  type: FETCH_SUBSCRIPTION_PRICE_REQUEST,
});
export const fetchPriceSuccess = data => ({
  type: FETCH_SUBSCRIPTION_PRICE_SUCCESS,
  payload: data,
});

export const fetchPriceError = e => ({
  type: FETCH_SUBSCRIPTION_PRICE_ERROR,
  error: true,
  payload: e,
});

export const fetchSubscriptionsRequest = () => ({
  type: FETCH_SUBSCRIPTIONS_REQUEST,
});
export const fetchSubscriptionsSuccess = data => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const fetchSubscriptionsError = e => ({
  type: FETCH_SUBSCRIPTION_PRICE_ERROR,
  error: true,
  payload: e,
});

export const createSubscriptionsRequest = (data) => ({
  type: CREATE_SUBSCRIPTIONS_REQUEST,
  payload:data
});
export const createSubscriptionsSuccess = data => ({
  type: CREATE_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const createSubscriptionsError = e => ({
  type: CREATE_SUBSCRIPTIONS_ERROR,
  error: true,
  payload: e,
});
export const getSubscriptionDataRequest = () => ({
  type: FETCH_SUBSCRIPTIONS_DATA_REQUEST,
});
export const getSubscriptionDataSuccess =( response) => ({
  type: FETCH_SUBSCRIPTIONS_DATA_SUCCESS,
  payload: response,
});

export const getSubscriptionDataError = e => ({
  type: FETCH_SUBSCRIPTIONS_DATA_ERROR,
  error: true,
  payload: e,
});
export const fetchListingRequest = () => ({
  type: FETCH_LISTING_REQUEST,
});
export const fetchListingSuccess =( response) => ({
  type: FETCH_LISTING_SUCCESS,
  payload: response,
});

export const fetchListingError = e => ({
  type: FETCH_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export const fetchPriceFromStripe = () => async (dispatch, getState, sdk) => {
  dispatch(fetchPriceRequest());
  try {
    const response = await getPriceFromStripe({});
    
    const sortedPrice = response.sort((planA, planB) => {
      const amountA = planA.unit_amount || 0;
      const amountB = planB.unit_amount || 0;
      return amountA - amountB;
    });
    
    dispatch(fetchPriceSuccess(sortedPrice));
  } catch (error) {
    dispatch(fetchPriceError(storableError(error)));
  }
};

//Create subscription with Stripe

export const createSubscription = params => async (dispatch, sdk, getState) => {
  dispatch(createSubscriptionsRequest(params.priceID));
  try {
    const subscription = await createSubscriptionWithStripe(params);
    await dispatch(getSubscriptionData())
    dispatch(fetchCurrentUser())
    dispatch(createSubscriptionsSuccess(subscription));
    return subscription;
  } catch (error) {
    dispatch(createSubscriptionsError(storableError(error)));
  }
};

export const getSubscriptionData = ()=>  (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const userId = currentUser?.id?.uuid;
  if (userId) {
    dispatch(getSubscriptionDataRequest());
    return getVendorSubscriptionStatus({
      searchFilter: `pagination[page]=${1}&pagination[pageSize]=${100}&filters[user_id]=${userId}`,
    })
      .then(response => {
        dispatch(getSubscriptionDataSuccess(response));
        return response;
      })
      .catch(err => {
        console.error(err, '---- getVendorCredits ---- => err');
        dispatch(getSubscriptionDataError(storableError(err)));
      });
  }
};

// Get subscriptions

export const getSubscriptions = (params) => async (dispatch, sdk, getState) => {
  dispatch(fetchSubscriptionsRequest());
  try {
    const subscriptions = await getSubscriptionsFromStripe(params);
    dispatch(fetchSubscriptionsSuccess(subscriptions));
    return subscriptions;
  } catch (error) {
    dispatch(fetchSubscriptionsError(storableError(error)));
  }
};

export const getListing = () => async (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest());
  const currentUser = getState().user.currentUser;
  const userId = currentUser?.id?.uuid;
  try {
    const response = await sdk.ownListings.query({
      authorId:userId
    });
    dispatch(fetchListingSuccess(response));
    return response;
  } catch (error) {
    dispatch(fetchListingError(storableError(error)));
  }
};

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {

  try {
    return Promise.all([
      dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] })),
      dispatch(fetchPriceFromStripe()),
    ]).then(()=>{
      dispatch(getSubscriptionData())
      dispatch(getListing())
    })
  } catch (error) {
    dispatch(showListingError(error));
    throw error;
  }
};
