//Action types

import { isScrollingDisabled } from '../../ducks/ui.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  createSubscriptionWithStripe,
  getPriceFromStripe,
  getSubscriptionsFromStripe,
  getVendorCredits,
} from '../../util/api';
import { storableError } from '../../util/errors';

export const GET_VENDER_CREDIT_REQUEST = 'app/StripeSubscriptionPage/GET_VENDER_CREDIT_REQUEST';
export const GET_VENDER_CREDIT_SUCCESS = 'app/StripeSubscriptionPage/GET_VENDER_CREDIT_SUCCESS';
export const GET_VENDER_CREDIT_ERROR = 'app/StripeSubscriptionPage/GET_VENDER_CREDIT_ERROR';

export const GET_VENDER_DEBIT_REQUEST = 'app/StripeSubscriptionPage/GET_VENDER_DEBIT_REQUEST';
export const GET_VENDER_DEBIT_SUCCESS = 'app/StripeSubscriptionPage/GET_VENDER_DEBIT_SUCCESS';
export const GET_VENDER_DEBIT_ERROR = 'app/StripeSubscriptionPage/GET_VENDER_DEBIT_ERROR';

//InitialState
const initialState = {
  PurchaseCredits: {},
  pagination: null,
  fetchPurchaseCreditsInProgress: false,
  fetchPurchaseCreditsError: null,


  vendorSpentCredits: {},
  fetchvendorSpentCreditsInProgress: false,
  fetchvendorSpentCreditsError: false,
};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
const TransactionHistoryPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VENDER_CREDIT_REQUEST:
      return {
        ...state,
        fetchPurchaseCreditsInProgress: true,
        fetchPurchaseCreditsError: null,
        PurchaseCredits: [],
        pagination: null
      };
    case GET_VENDER_CREDIT_SUCCESS:
      return {
        ...state,
        fetchPurchaseCreditsInProgress: false,
        PurchaseCredits:payload
      };
    case GET_VENDER_CREDIT_ERROR:
      return {
        ...state,
        fetchPurchaseCreditsInProgress: false,
        fetchPurchaseCreditsError: payload,
        PurchaseCredits: [],
        pagination: null
      };
    case GET_VENDER_DEBIT_REQUEST:
      return {
        ...state,
        fetchvendorSpentCreditsInProgress: true,
        fetchvendorSpentCreditsError: null,
        vendorSpentCredits: [],
      };
    case GET_VENDER_DEBIT_SUCCESS:
      return {
        ...state,
        fetchvendorSpentCreditsInProgress: false,
        vendorSpentCredits: payload
      };
    case GET_VENDER_DEBIT_ERROR:
      return {
        ...state,
        fetchvendorSpentCreditsInProgress: false,
        fetchvendorSpentCreditsError: payload,
        vendorSpentCredits: [],
        // pagination: null,
      };


    default:
      return state;
  }
}

export default TransactionHistoryPageReducer;

// ================ Action creators ================ //

export const fetchCreditsRequest = () => ({
  type: GET_VENDER_CREDIT_REQUEST,
});
export const fetchCreditsSuccess = response => ({
  type: GET_VENDER_CREDIT_SUCCESS,
  payload: response,
});

export const fetchCreditsError = e => ({
  type: GET_VENDER_CREDIT_ERROR,
  error: true,
  payload: e,
});

export const fetchDebitsRequest = () => ({
  type: GET_VENDER_DEBIT_REQUEST,
});
export const fetchDebitsSuccess = response => ({
  type: GET_VENDER_DEBIT_SUCCESS,
  payload: response,
});

export const fetchDebitsError = e => ({
  type: GET_VENDER_DEBIT_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

export const buyVendorCredits = params => (dispatch, getState, sdk) => {
    const currentUser = getState().user.currentUser;
    const userId = currentUser?.id?.uuid;
  
    if(userId){
      dispatch(fetchCreditsRequest());
      return getVendorCredits({
        searchFilter: `pagination[page]=${1}&pagination[pageSize]=${100}&filters[userId]=${userId}&filters[type]=CREDIT`,
      })
        .then(response => {
          dispatch(fetchCreditsSuccess(response));
          return response;
        })
        .catch(err => {
          console.error(err, '---- getVendorCredits ---- => err');
          dispatch(fetchCreditsError(storableError(err)));
        });
    }
  
  };

  export const spentVendorCredits = params => (dispatch, getState, sdk) => {
    const currentUser = getState().user.currentUser;
    const userId = currentUser?.id?.uuid;
  
    if(userId){
      dispatch(fetchDebitsRequest());
      return getVendorCredits({
        searchFilter: `pagination[page]=${1}&pagination[pageSize]=${100}&filters[userId]=${userId}&filters[type]=DEBIT`,
      })
        .then(response => { 
          dispatch(fetchDebitsSuccess(response));
          return response;
        })
        .catch(err => {
          console.error(err, '---- getVendorCredits ---- => err');
          dispatch(fetchDebitsError(storableError(err)));
        });
    }
  };

//LoadData
export const loadData = params => async (dispatch, getState, sdk) => {

  try {
    return Promise.all([
      dispatch(buyVendorCredits()),
      dispatch(spentVendorCredits()),
    ]);
  } catch (error) {
    console.log(error, '&&& &&& => error');
    
    throw error;
  }
};
