import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import {
  approveCustomerListing,
  contactUsMessage,
  createUserWithIdp,
  fetchUsers,
  fetchVendors,
  getDistance,
  phoneNumberExists,
  sendOtp,
  verifyOtp,
} from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import {
  HIRE_FIXED,
  HIRE_FIXED_CREDIT,
  PLANNING_TO_HIRE,
  PLANNING_TO_HIRE_CREDIT,
  R_NOT_SURE,
  R_NOT_SURE_CREDIT,
  USER_TYPE_CUSTOMER,
  USER_TYPE_VENDOR,
} from '../util/types';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/auth/CONFIRM_ERROR';

export const CUSTOMER_REQUEST = 'app/auth/CUSTOMER_REQUEST';
export const CUSTOMER_SUCCESS = 'app/auth/CUSTOMER_SUCCESS';
export const CUSTOMER_ERROR = 'app/auth/CUSTOMER_ERROR';

export const VENDORS_REQUEST = 'app/auth/VENDORS_REQUEST';
export const VENDORS_SUCCESS = 'app/auth/VENDORS_SUCCESS';
export const VENDORS_ERROR = 'app/auth/VENDORS_ERROR';

export const OTP_SIGNUP_REQUEST = 'app/Auth/OTP_SIGNUP_REQUEST';
export const OTP_SIGNUP_ERROR = 'app/Auth/OTP_SIGNUP_ERROR';
export const OTP_SIGNUP_SUCCESS = 'app/Auth/OTP_SIGNUP_SUCCESS';

export const OTP_CHECK_REQUEST = 'app/Auth/OTP_CHECK_REQUEST';
export const OTP_CHECK_ERROR = 'app/Auth/OTP_CHECK_ERROR';
export const OTP_CHECK_SUCCESS = 'app/Auth/OTP_CHECK_SUCCESS';

export const PHONE_NUMBER_CHECK_REQUESTED = 'app/Auth/PHONE_NUMBER_CHECK_REQUESTED';
export const PHONE_NUMBER_CHECK_ERROR = 'app/Auth/PHONE_NUMBER_CHECK_ERROR';
export const PHONE_NUMBER_CHECK_SUCCESS = 'app/Auth/PHONE_NUMBER_CHECK_SUCCESS';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,
  signupSuccess: false,
  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,

  fetchUser: [],
  fetchUserInProgress: false,
  fetchUsererror: null,

  fetchVendors: [],
  fetchVendorsInProgress: false,
  fetchVendorserror: null,

  // otp signup
  otpData: null,
  otpError: null,
  otpInProgress: false,

  // otp check
  otpCheckData: null,
  otpCheckError: null,
  otpCheckInProgress: false,

  //PhoneNumber Check
  phoneNumberCheckInProgress: false,
  phoneNumberCheckError: null,
  phoneNumberExists: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false, signupSuccess: true };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    case CUSTOMER_REQUEST:
      return { ...state, fetchUserInProgress: true, fetchUsererror: null };
    case CUSTOMER_SUCCESS:
      return { ...state, fetchUserInProgress: false, fetchUser: payload };
    case CUSTOMER_ERROR:
      return { ...state, fetchUserInProgress: false, fetchUsererror: payload, fetchUser: [] };

    case VENDORS_REQUEST:
      return { ...state, fetchVendorsInProgress: true, fetchVendorserror: null };
    case VENDORS_SUCCESS:
      return { ...state, fetchVendorsInProgress: false, fetchVendors: payload };
    case VENDORS_ERROR:
      return { ...state, fetchVendorsInProgress: false, fetchVendorserror: payload, fetchUser: [] };

    case OTP_SIGNUP_REQUEST:
      return { ...state, otpInProgress: true, otpError: null };
    case OTP_SIGNUP_SUCCESS:
      return { ...state, otpInProgress: false, otpData: payload };
    case OTP_SIGNUP_ERROR:
      return { ...state, otpInProgress: false, otpError: payload };

    case OTP_CHECK_REQUEST:
      return { ...state, otpCheckInProgress: true, otpCheckError: null };
    case OTP_CHECK_SUCCESS:
      return { ...state, otpCheckInProgress: false, otpCheckData: payload };
    case OTP_CHECK_ERROR:
      return { ...state, otpCheckInProgress: false, otpCheckError: payload };

    case PHONE_NUMBER_CHECK_REQUESTED:
      return {
        ...state,
        phoneNumberCheckInProgress: true,
        phoneNumberCheckError: null,
        phoneNumberExists: false,
      };
    case PHONE_NUMBER_CHECK_SUCCESS:
      return {
        ...state,
        doesPhoneExist: payload,
        phoneNumberCheckInProgress: false,
      };
    case PHONE_NUMBER_CHECK_ERROR:
      return {
        ...state,
        phoneNumberCheckInProgress: false,
        phoneNumberCheckError: payload,
        phoneNumberExists: false,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const fetchCustomerRequest = () => ({ type: CUSTOMER_REQUEST });
export const fetchCustomerSuccess = response => ({ type: CUSTOMER_SUCCESS, payload: response });
export const fetchCustomerError = error => ({ type: CUSTOMER_ERROR, payload: error, error: true });

export const fetchVendorsRequest = () => ({ type: VENDORS_REQUEST });
export const fetchVendorsSuccess = response => ({ type: VENDORS_SUCCESS, payload: response });
export const fetchVendorsError = error => ({ type: VENDORS_ERROR, payload: error, error: true });

const otpSignupRequest = () => ({ type: OTP_SIGNUP_REQUEST });
const otpSignupSucess = resp => ({ type: OTP_SIGNUP_SUCCESS, payload: resp });
const otpSignupError = e => ({ type: OTP_SIGNUP_ERROR, error: true, payload: e });

const otpSignupCheckRequest = () => ({ type: OTP_CHECK_REQUEST });
const otpSignupCheckSucess = resp => ({ type: OTP_CHECK_SUCCESS, payload: resp });
const otpSignupCheckError = e => ({ type: OTP_CHECK_ERROR, error: true, payload: e });

export const phoneNumberCheckRequested = () => ({
  type: PHONE_NUMBER_CHECK_REQUESTED,
});
export const phoneNumberCheckError = error => ({
  type: PHONE_NUMBER_CHECK_ERROR,
  payload: error,
  error: true,
});
export const phoneNumberCheckSuccess = bool => ({
  type: PHONE_NUMBER_CHECK_SUCCESS,
  payload: bool,
});

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password, userSignUp) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(async () => {
      dispatch(loginSuccess());
      await dispatch(fetchCurrentUser());
      const currentUser = getState().user.currentUser;

      const {
        userListingIDs,
        userListingID = false,
        location,
        userRole,
        eventBudget,
        OtherEventBudget,
        eventDate,
        eventHiringDecision,
        OtherEventHiringDecision,
        eventTypePlanning,
        OtherEventTypePlanning,
        serviceType,
        serviceTypeCategory,
        serviceTypeValues,
        specificCulture,
        OtherSpecificCulture,
        terms,
        phoneNumber,
        date,
        dateNotconfirmed,
        referralCode,
        isReferralCode,
        transactionProcessAlias,
      } = currentUser?.attributes?.profile?.publicData;
      const geolocation = location?.selectedPlace?.origin || null;

      const { firstName } = currentUser?.attributes?.profile;
      const email = currentUser?.attributes?.email;

      const listingData = {
        title: firstName,
        geolocation: geolocation ? geolocation : null,
        publicData: {
          email,
          eventBudget,
          OtherEventBudget,
          eventDate,
          eventHiringDecision,
          OtherEventHiringDecision,
          eventTypePlanning,
          OtherEventTypePlanning,
          location,
          serviceType,
          serviceTypeCategory,
          serviceTypeValues,
          specificCulture,
          OtherSpecificCulture,
          terms,
          userRole,
          referralCode,
          phoneNumber,
          transactionProcessAlias,
          date,
          dateNotconfirmed,
          custmerView: false,
        },
      };
      if (userRole === USER_TYPE_CUSTOMER && userSignUp) {
        const userListingIDsUpdated =
          userListingIDs && Array.isArray(userListingIDs) ? userListingIDs : [];
        await sdk.ownListings.create(listingData).then(async response => {
          const listingId = response.data.data.id.uuid;
          userListingIDsUpdated.push(listingId);
          const updatedValues = {
            publicData: {
              userListingID: listingId,
              userListingIDs: userListingIDsUpdated,
            },
          };
          setTimeout(() => {
            dispatch(approveCustomerListing({ listingId: listingId }));
          }, 500);
          await sdk.currentUser.updateProfile(updatedValues).then(async r => {
            await dispatch(fetchCurrentUser());
          });
        });
      }

      if (userRole === USER_TYPE_VENDOR) {
        const category =
          currentUser &&
          currentUser.attributes &&
          currentUser.attributes.profile &&
          currentUser.attributes.profile.publicData &&
          currentUser.attributes.profile.publicData.category;

        if (category) {
          await dispatch(
            fetchCustomer({
              pub_serviceTypeCategory: `has_any:${category}`,
              pub_userRole: USER_TYPE_CUSTOMER,
            })
          );
        }
      } else if (userRole === USER_TYPE_CUSTOMER) {
        const serviceType = currentUser?.attributes?.profile?.publicData?.serviceType
          ?.map(item => item.label)
          .join(',');

        if (userRole === USER_TYPE_CUSTOMER && serviceType) {
          await dispatch(
            fetchAllVendors({
              pub_category: `has_any:${serviceType}`,
              pub_userRole: USER_TYPE_VENDOR,
              currentUser: currentUser,
            })
          );
        }
      }
      return true;
    })
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, ...rest } = params;

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName }
    : {
        email,
        password,
        firstName,
        lastName,
        publicData: { ...rest },
      };
  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(signupSuccess()))
    .then(() => dispatch(login(email, password, true)))
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const customerListing = listingParams => async (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const { userListingID, userRole, userListingIDs } = currentUser?.attributes?.profile?.publicData;
  const {
    eventBudget,
    OtherEventBudget,
    eventDate,
    eventHiringDecision,
    OtherEventHiringDecision,
    eventTypePlanning,
    OtherEventTypePlanning,
    serviceType,
    serviceTypeValues,
    serviceTypeCategory,
    location,
    specificCulture,
    transactionProcessAlias,
    date,
    dateNotconfirmed,
    OtherSpecificCulture,
  } = listingParams?.publicData;
  const geolocation = location?.selectedPlace?.origin || null;

  const { firstName, publicData } = currentUser?.attributes?.profile || {};
  const email = currentUser?.attributes?.email || '';
  const phoneNumber = publicData.phoneNumber || '';
  const listingData = {
    title: firstName,
    geolocation: geolocation ? geolocation : null,
    publicData: {
      email,
      eventBudget,
      OtherEventBudget,
      eventDate,
      eventHiringDecision,
      OtherEventHiringDecision,
      location,
      eventTypePlanning,
      OtherEventTypePlanning,
      serviceType,
      serviceTypeValues,
      phoneNumber,
      serviceTypeCategory,
      specificCulture,
      OtherSpecificCulture,
      userRole,
      transactionProcessAlias,
      date,
      custmerView: false,
      dateNotconfirmed: true ? "I'm not sure yet" : null,
    },
  };
  if (userRole === USER_TYPE_CUSTOMER && userListingID) {
    try {
      const userListingIDsUpdated =
        userListingIDs && Array.isArray(userListingIDs) ? userListingIDs : [];
      const response = await sdk.ownListings.create(listingData);
      const listingId = response.data.data.id.uuid;
      userListingIDsUpdated.push(listingId);

      const updatedValues = {
        publicData: {
          userListingID: listingId,
          userListingIDs: userListingIDsUpdated,
        },
      };
      setTimeout(() => {
        dispatch(approveCustomerListing({ listingId: listingId }));
      }, 500);

      await sdk.currentUser.updateProfile(updatedValues).then(async r => {
        await dispatch(fetchCurrentUser());
      });

      return 'true';
    } catch (error) {
      console.error(error, '&&& &&& => error');
      return Promise.reject(error);
    }
  }
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(res => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};

export const fetchCustomer = params => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchCustomerRequest());
    const response = await fetchUsers(params);
    dispatch(fetchCustomerSuccess(response));
  } catch (error) {
    console.log(error, 'error');
    dispatch(fetchCustomerError(storableError(e)));
  }
};

export const fetchAllVendors = params => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchVendorsRequest());
    await dispatch(fetchCurrentUser());
    const currentUser = getState().user.currentUser;
    const response = await fetchVendors(params);
    const allvendorEmail =
      (response?.data && response?.data.length && response?.data?.split(',')) || [];

    const { firstName, lastName } = currentUser?.attributes?.profile || '';
    const customerName = firstName + ' ' + lastName || '';
    const {
      serviceType,
      eventHiringDecision,
      OtherEventHiringDecision,
      eventBudget,
      OtherEventBudget,
      eventTypePlanning,
      OtherEventTypePlanning,
      location,
      phoneNumber,
      date,
      userListingID,
    } = currentUser?.attributes?.profile?.publicData || '';
    const email = currentUser?.attributes?.email || '';
    const customerServiceType = serviceType?.map(st => st.label) || [];

    const maskEmail = email => {
      if (email?.includes('@')) {
        let [username, rest] = email?.split('@');
        let [domain, extension] = rest?.split('.');
        let maskedUsername = username[0] + '*'?.repeat(username.length - 2) + username.slice(-1);
        let maskedDomain = domain[0] + '*'?.repeat(domain.length - 2) + domain.slice(-1);
        let maskedEmail = maskedUsername + '@' + maskedDomain + '.' + extension;
        return maskedEmail;
      } else {
        return email;
      }
    };

    let maskedEmail = maskEmail(email);

    const maskPhoneNumber = phoneNumber => {
      return phoneNumber?.substring(0, 3) + '*'.repeat(Math.max(0, phoneNumber?.length - 3));
    };
    const maskedPhoneNumber = maskPhoneNumber(phoneNumber);

    const searchParts = location?.search?.split(', ') || '';
    const lastElement = (searchParts && searchParts[searchParts?.length - 1]) || '';
    const userLocation = location?.search ? location?.search : null;
    const requiredCredit =
      eventHiringDecision && eventHiringDecision == R_NOT_SURE
        ? R_NOT_SURE_CREDIT
        : eventHiringDecision && eventHiringDecision == PLANNING_TO_HIRE
        ? PLANNING_TO_HIRE_CREDIT
        : eventHiringDecision && eventHiringDecision == HIRE_FIXED
        ? HIRE_FIXED_CREDIT
        : 10;

    allvendorEmail &&
      allvendorEmail.length > 0 &&
      (await contactUsMessage({
        customerName: customerName,
        userLocation: userLocation,
        maskedEmail: maskedEmail,
        maskedPhoneNumber: maskedPhoneNumber,
        eventDate: date ? date : "I'm not sure yet",
        eventBudget: OtherEventBudget ? OtherEventBudget : eventBudget,
        eventHiringDecision: OtherEventHiringDecision
          ? OtherEventHiringDecision
          : eventHiringDecision,
        eventTypePlanning: OtherEventTypePlanning ? OtherEventTypePlanning : eventTypePlanning,
        requiredCredit: requiredCredit,
        customerListingId: userListingID,
        customerServiceType: customerServiceType,
        customerId: allvendorEmail,
        senderId: 'info@myfindor.com',
        subject: `${firstName} is looking for ${customerServiceType} in ${lastElement}`,
        text: 'text',
        sendToAllvendors: 'sendToAllvendors',
      }));

    dispatch(fetchVendorsSuccess(response));
  } catch (error) {
    console.log(error, 'error');
    dispatch(fetchVendorsError(storableError(e)));
  }
};

export const onGetOtpSignup = params => (dispatch, getState, sdk) => {
  dispatch(otpSignupRequest());

  return sendOtp(params)
    .then(resp => {
      dispatch(otpSignupSucess(resp));
      return resp;
    })
    .catch(e => {
      dispatch(otpSignupError(storableError(e)));
    });
};

export const onOtpSignUpCheck = params => (dispatch, getState, sdk) => {
  dispatch(otpSignupCheckRequest());
  return verifyOtp(params)
    .then(async resp => {
      dispatch(otpSignupCheckSucess(resp));
      return resp;
    })
    .catch(e => dispatch(otpSignupCheckError(storableError(e))));
};

export const checkPhoneNumber = phoneNumber => async (dispatch, getState, sdk) => {
  dispatch(phoneNumberCheckRequested());
  try {
    const response = await phoneNumberExists({ phoneNumber });

    dispatch(phoneNumberCheckSuccess(response));
  } catch (error) {
    dispatch(phoneNumberCheckError(storableError(error)));
  }
};
