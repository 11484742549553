import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonLoader = props => {
  const { viewport, isMobile, noOfSlides, isInboxPage } = props;

  let slideWidth = viewport && noOfSlides ? viewport.width / noOfSlides - 18 : 276;
  const slideViewBoxWidth = viewport && noOfSlides && viewport.width / noOfSlides;
  const LayoutMobile = typeof window !== 'undefined' && window.innerWidth < 576;

  if (slideWidth < 0) {
    slideWidth = 462
  };

  if (isMobile) {
    return <ContentLoader
      speed={2}
      width={210}
      height={230}
      viewBox="0 0 210 230"
      backgroundColor="#dddddd"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="29" y="85" rx="0" ry="0" width="103" height="8" />
      <rect x="3" y="5" rx="8" ry="8" width="204" height="138" />
      <rect x="6" y="152" rx="0" ry="0" width="138" height="11" />
      <rect x="6" y="171" rx="0" ry="0" width="153" height="10" />
      <rect x="6" y="191" rx="0" ry="0" width="130" height="11" />
      <rect x="6" y="212" rx="0" ry="0" width="92" height="11" />
    </ContentLoader>
  }
  else if (isInboxPage) {

    if (LayoutMobile) {
      return <ContentLoader
        height={90}
        width={320}
        viewBox="0 0 320 54"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="25" cy="25" r="22" />
        <rect x="60" y="8" rx="5" ry="0" width="60%" height="15" />

        <rect x="60" y="29" rx="3" ry="0" width="90%" height="15" />

        <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
      </ContentLoader>
    }
    else {
      return <ContentLoader
        height={90}
        width={720}
        viewBox="0 0 720 54"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <circle cx="25" cy="25" r="22" />
        <rect x="60" y="8" rx="5" ry="0" width="30%" height="15" />
        <rect x="650" y="8" rx="5" ry="0" width="10%" height="15" />
        <rect x="60" y="29" rx="3" ry="0" width="40%" height="15" />
        <rect x="620" y="29" rx="3" ry="0" width="15%" height="15" />

        <rect x="0" y="100" rx="0" ry="0" width="100%" height="1" />
      </ContentLoader>
    }

  }

  return (
    <ContentLoader
      speed={4}
      width={450}
      height={500}
      viewBox={`0 0 ${slideViewBoxWidth} 334`}
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="0" y="0" rx="8" ry="8" width="450" height="243" />
      <path d="M 0 257 h 440 v 15 H 0 z" />
      <path d="M 0 275 h 440 v 15 H 0 z" />
      <path d="M 0 299 h 174 v 40 H 0 z" />
      <path d="M 0 350 h 440 v 15 H 0 z" />
    </ContentLoader>
  )
};

export default SkeletonLoader;
