import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  fetchUsers,
  fetchUsersListings,
  getDistance,
  getVendorCredits,
  spentVendorCredits,
  updateListingAfterView,
} from '../../util/api';
import { USER_TYPE_VENDOR } from '../../util/types';
import { updateProfile, updateProfileUser } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { denormalisedEntities, updatedEntities } from '../../util/data';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const CUSTOMER_LISTINGS_REQUEST = 'app/SearchPage/CUSTOMER_LISTINGS_REQUEST';
export const CUSTOMER_LISTINGS_SUCCESS = 'app/SearchPage/CUSTOMER_LISTINGS_SUCCESS';
export const CUSTOMER_LISTINGS_ERROR = 'app/SearchPage/CUSTOMER_LISTINGS_ERROR';

export const GET_VENDOR_CREDIT_REQUEST = 'app/SearchPage/GET_VENDOR_CREDIT_REQUEST';
export const GET_VENDOR_CREDIT_SUCCESS = 'app/SearchPage/GET_VENDOR_CREDIT_SUCCESS';
export const GET_VENDOR_CREDIT_ERROR = 'app/SearchPage/GET_VENDOR_CREDIT_ERROR';

export const GET_VENDER_DEBIT_REQUEST = 'app/SearchPage/GET_VENDER_DEBIT_REQUEST';
export const GET_VENDER_DEBIT_SUCCESS = 'app/SearchPage/GET_VENDER_DEBIT_SUCCESS';
export const GET_VENDER_DEBIT_ERROR = 'app/SearchPage/GET_VENDER_DEBIT_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/SearchPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/SearchPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/SearchPage/FETCH_TRANSACTIONS_ERROR';

export const CUSTOMER_VIEW_LISTING_REQUEST = 'app/SearchPage/CUSTOMER_VIEW_LISTING_REQUEST';
export const CUSTOMER_VIEW_LISTING_SUCCESS = 'app/SearchPage/CUSTOMER_VIEW_LISTING_SUCCESS';
export const CUSTOMER_VIEW_LISTING_ERROR = 'app/SearchPage/CUSTOMER_VIEW_LISTING_ERROR';

export const CUSTOMER_LISTING_APPROVED_REQUEST = 'app/SearchPage/CUSTOMER_LISTING_APPROVED_REQUEST';
export const CUSTOMER_LISTING_APPROVED_SUCCESS = 'app/SearchPage/CUSTOMER_LISTING_APPROVED_SUCCESS';
export const CUSTOMER_LISTING_APPROVED_ERROR = 'app/SearchPage/CUSTOMER_LISTING_APPROVED_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],

  fetchUserListings: [],
  fetchUserListingsInProgress: false,
  fetchUserListingserror: null,

  vendorCredits: [],
  fetchVendorCreditsInProgress: false,
  fetchVendorCreditsError: false,

  vendorSpentCredits: {},
  fetchvendorSpentCreditsInProgress: false,
  fetchvendorSpentCreditsError: false,

  transactions: null,
  fetchtransactionInProgress: false,
  fetchtransactionError: null,

  listingApproved: null,
  listingApprovedInProgress: false,
  listingApprovedError: false,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case CUSTOMER_LISTINGS_REQUEST:
      return {
        ...state,
        fetchUserListingsInProgress: true,
        fetchUserListingserror: null,
      };
    case CUSTOMER_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchUserListingsInProgress: false,
        fetchUserListings: payload,
      };
    case CUSTOMER_LISTINGS_ERROR:
      return {
        ...state,
        fetchUserListingsInProgress: false,
        fetchUserListingserror: payload,
        fetchUserListings: [],
      };

    case GET_VENDOR_CREDIT_REQUEST:
      return {
        ...state,
        fetchVendorCreditsInProgress: true,
        fetchVendorCreditsError: null,
        vendorCredits: [],
        // pagination: null,
      };
    case GET_VENDOR_CREDIT_SUCCESS:
      return {
        ...state,
        fetchVendorCreditsInProgress: false,
        vendorCredits: payload.totalCredits || 0,
      };
    case GET_VENDOR_CREDIT_ERROR:
      return {
        ...state,
        fetchVendorCreditsInProgress: false,
        fetchVendorCreditsError: payload,
        vendorCredits: [],
        // pagination: null,
      };

    case GET_VENDER_DEBIT_REQUEST:
      return {
        ...state,
        fetchvendorSpentCreditsInProgress: true,
        fetchvendorSpentCreditsError: null,
        vendorSpentCredits: [],
      };
    case GET_VENDER_DEBIT_SUCCESS:
      return {
        ...state,
        fetchvendorSpentCreditsInProgress: false,
        vendorSpentCredits: payload,
      };
    case GET_VENDER_DEBIT_ERROR:
      return {
        ...state,
        fetchvendorSpentCreditsInProgress: false,
        fetchvendorSpentCreditsError: payload,
        vendorSpentCredits: [],
        // pagination: null,
      };

    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchtransactionInProgress: true, fetchtransactionError: null };
    case FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, fetchtransactionInProgress: false, transactions: payload };
    case FETCH_TRANSACTIONS_ERROR:
      return { ...state, fetchtransactionInProgress: false, fetchtransactionError: payload };

    case CUSTOMER_LISTING_APPROVED_REQUEST:
      return { ...state, listingApprovedInProgress: true, listingApprovedError: null };
    case CUSTOMER_LISTING_APPROVED_SUCCESS:
      return { ...state, listingApprovedInProgress: false, listingApproved: payload };
    case CUSTOMER_LISTING_APPROVED_ERROR:
      return { ...state, listingApprovedInProgress: false, listingApprovedError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchCustomerListingsRequest = () => ({
  type: CUSTOMER_LISTINGS_REQUEST,
});
export const fetchCustomerListingsSuccess = response => ({
  type: CUSTOMER_LISTINGS_SUCCESS,
  payload: response,
});
export const fetchCustomerListingsError = error => ({
  type: CUSTOMER_LISTINGS_ERROR,
  payload: error,
  error: true,
});

export const fetchVendorCreditsRequest = () => ({
  type: GET_VENDOR_CREDIT_REQUEST,
});
export const fetchVendorCreditsSuccess = response => ({
  type: GET_VENDOR_CREDIT_SUCCESS,
  payload: response,
});
export const fetchVendorCreditsError = error => ({
  type: GET_VENDOR_CREDIT_ERROR,
  error: true,
  payload: error,
});

export const fetchDebitsRequest = () => ({
  type: GET_VENDER_DEBIT_REQUEST,
});
export const fetchDebitsSuccess = response => ({
  type: GET_VENDER_DEBIT_SUCCESS,
  payload: response,
});

export const fetchDebitsError = e => ({
  type: GET_VENDER_DEBIT_ERROR,
  error: true,
  payload: e,
});

export const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
export const fetchTransactionsSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: response,
});
export const fetchTransactionsError = error => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: error,
});

export const fetchApprovedListngRequest = () => ({
  type: CUSTOMER_LISTING_APPROVED_REQUEST,
});

export const fetchApprovedListngSuccess = response => {
  return {
    type: CUSTOMER_LISTING_APPROVED_SUCCESS,
    payload: response,
  };
};

export const fetchApprovedListngError = error => ({
  type: CUSTOMER_LISTING_APPROVED_ERROR,
  error: true,
  payload: error,
});

// ========================= Thunks ======================= //

export const availableVendorCredits = params => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const userId = currentUser?.id?.uuid;

  if (userId) {
    dispatch(fetchVendorCreditsRequest());
    return getVendorCredits({
      searchFilter: `pagination[page]=${1}&pagination[pageSize]=${100}&filters[userId]=${userId}&filters[type]=CREDIT`,
    })
      .then(response => {
        dispatch(fetchVendorCreditsSuccess(response));
        return response;
      })
      .catch(err => {
        console.error(err, '---- getVendorCredits ---- => err');
        dispatch(fetchVendorCreditsError(storableError(err)));
      });
  }
};

export const isVendorCredits = params => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const userId = currentUser?.id?.uuid;

  if (userId) {
    dispatch(fetchDebitsRequest());
    return getVendorCredits({
      searchFilter: `pagination[page]=${1}&pagination[pageSize]=${100}&filters[userId]=${userId}&filters[type]=DEBIT`,
    })
      .then(response => {
        dispatch(fetchDebitsSuccess(response));
        return response;
      })
      .catch(err => {
        console.error(err, '---- getVendorCredits ---- => err');
        dispatch(fetchDebitsError(storableError(err)));
      });
  }
};

export const vendorSpentCredits = (params, totalCredits) => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const tempData = currentUser?.attributes?.profile?.protectedData?.tempData || [];
  const { userId, ...rest } = params;
  tempData.push({ ...rest, totalCredits });

  return spentVendorCredits({ ...params })
    .then(response => {
      if (response) {
        dispatch(
          updateProfileUser({
            protectedData: {
              tempData: tempData,
              totalCredits: totalCredits,
            },
          })
        );
      }
      return response;
    })
    .catch(err => {
      console.error(err, '---- getVendorCredits ---- => err');
    });
};

export const getProperResponseOfListing = (response, config) => {
  return response.data.data.map(st => {
    const { id, type, attributes, relationships } = st;
    const authorId = relationships?.author?.data?.id?.uuid;
    const authorDetails =
      authorId &&
      response?.data?.included.length &&
      response?.data?.included.filter(st => st.id.uuid == authorId);
    return authorDetails && authorDetails.length
      ? { id, type, attributes, author: authorDetails[0] }
      : { id, type, attributes };
  });
};

export const fetchCustomerListings = config => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchCustomerListingsRequest());

    const currentUser = getState().user.currentUser;
    const { publicData } = currentUser?.attributes?.profile || {};
    const isVendor = publicData?.userRole == USER_TYPE_VENDOR;
    const category = publicData?.category;
    const distanceInKilometers = parseInt(publicData?.distance) * 1.60934;
    const lat = publicData?.location?.selectedPlace?.origin?.lat;
    const lng = publicData?.location?.selectedPlace?.origin?.lng;

    const calculateBounds = (centerLat, centerLng, radiusInKm) => {
      const earthRadius = 6371; // Earth radius in kilometers
      // Convert radius from kilometers to radians
      const radiusInRadians = radiusInKm / earthRadius;
      // Convert center coordinates to radians
      const centerLatRad = (centerLat * Math.PI) / 180;
      const centerLngRad = (centerLng * Math.PI) / 180;
      // Calculate the NE and SW points
      const neLat = centerLat + (radiusInRadians * 180) / Math.PI;
      const neLng = centerLng + (radiusInRadians * 180) / Math.PI / Math.cos(centerLatRad);
      const swLat = centerLat - (radiusInRadians * 180) / Math.PI;
      const swLng = centerLng - (radiusInRadians * 180) / Math.PI / Math.cos(centerLatRad);
      return {
        neLat,
        neLng,
        swLat,
        swLng,
      };
    };
    const bounds = calculateBounds(lat, lng, distanceInKilometers);
    const params = {
      pub_serviceTypeCategory: `has_any:${category}`,
      pub_userRole: 'customer',
      ...(!isNaN(distanceInKilometers)
        ? {
            bounds: `${bounds.neLat},${bounds.neLng},${bounds.swLat},${bounds.swLng}`,
          }
        : {}),
    };
    return fetchUsersListings(params)
      .then(response => {
        const properResponse = getProperResponseOfListing(response);

        dispatch(fetchCustomerListingsSuccess(properResponse));
      })
      .catch(e => {
        dispatch(fetchCustomerListingsError(storableError(e)));
      });
  } catch (error) {
    console.error(error, 'error');
    dispatch(fetchCustomerListingsError(storableError(error)));
  }
};

export const searchListings = (searchParams, config) => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const currentUser = getState().user.currentUser;
  const { publicData } = currentUser?.attributes?.profile || {};
  const isVendor = publicData?.userRole == USER_TYPE_VENDOR;
  const locationUser = currentUser?.attributes?.profile?.publicData?.location?.search;
  const parts = locationUser?.split(' ') ?? '';
  const customerCountry = parts[parts?.length - 1] ?? '';
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
          pub_listingType: listingTypes.map(l => l.listingType),
        }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
        ? getExclusiveEndDate(endRaw, searchTZ)
        : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;

    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
          start: getProlongedStart(startDate),
          end: getProlongedEnd(endDate),
          availability: 'time-partial',
          // minDuration uses minutes
          minDuration,
        }
      : {};
  };

  const stockFilters = datesMaybe => {
    const hasDatesFilterInUse = Object.keys(datesMaybe).length > 0;
    return hasDatesFilterInUse ? {} : { minStock: 1, stockMode: 'match-undefined' };
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const stockMaybe = stockFilters(datesMaybe);
  // const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };
  const sortMaybe = !isVendor
    ? sort === config.search.sortConfig.relevanceKey
      ? {}
      : { sort: 'pub_updatedAt' }
    : sort === config.search.sortConfig.relevanceKey
    ? {}
    : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
  };
  const origin = currentUser?.attributes?.profile?.publicData?.location?.selectedPlace?.origin;

  try {
    const response = await sdk.listings.query(params);
    const listings = response.data.data;

    if (!isVendor) {
      const listingsWithDistance = await Promise.all(
        listings.map(async listing => {
          const { location, country } = listing.attributes.publicData;

          const vendorCountry = location?.search?.split(' ').pop() || country;

          if (vendorCountry === customerCountry) {
            const listingOrigin = location?.selectedPlace?.origin;

            const res = listingOrigin && (await getDistance({ listingOrigin, origin }));

            const distanceInMiles = res?.data ? res.data * 0.00062137119 : null;
            return { ...listing, distanceInMiles };
          }
          return listing;
        })
      );

      const filteredListings = listingsWithDistance.filter(listing => {
        if (!listing || listing.distanceInMiles === undefined) return false;
        const distanceInMiles = listing.distanceInMiles;
        const { availabilityVendor, distance } = listing.attributes.publicData;
        const modifiedDistance = Number(distance?.split(' ')[0]);
        if (availabilityVendor.includes('nationwide')) {
          return listing.attributes.publicData.country === customerCountry;
        } else if (distanceInMiles < modifiedDistance) {
          return listing;
        }
        return false;
      });
      response.data.data = filteredListings;
    }

    const listingFields = config?.listing?.listingFields;
    const sanitizeConfig = { listingFields };

    dispatch(addMarketplaceEntities(response, sanitizeConfig));
    await dispatch(searchListingsSuccess(response));
    return response;
  } catch (e) {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  }
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const fetchTransaction = userId => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionsRequest());

  sdk.transactions
    .query({
      userId: userId,
      lastTransitions: ['transition/inquire-booking'],
      include: ['customer', 'provider', 'listing'],
    })
    .then(res => {
      const response = res.data.data;
      dispatch(fetchTransactionsSuccess(response));
    })
    .catch(e => {
      dispatch(fetchTransactionsError(e));
    });
};

export const customerListingView = params => (dispatch, getState, sdk) => {
  return updateListingAfterView(params)
    .then(response => {})
    .catch(err => {
      console.error(err, '---- getVendorCredits ---- => err');
    });
};

export const checkListingApproved = params => (dispatch, getState, sdk) => {
  dispatch(fetchApprovedListngRequest());
  const currentUser = getState().user.currentUser;
  const listingId = currentUser?.attributes?.profile?.publicData?.userListingId;
  sdk.ownListings
    .query({ listingId: listingId })
    .then(res => {
      const response = res.data.data;
      dispatch(fetchApprovedListngSuccess(response));
    })
    .catch(e => {
      dispatch(fetchApprovedListngError(e));
    });
};

export const loadData = (params, search, config) => async (dispatch, getState) => {
  const { type } = params;
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  await dispatch(fetchCurrentUser());
  const { currentUser } = getState().user;
  const userId = currentUser?.id.uuid;

  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return Promise.all([
    dispatch(
      searchListings(
        {
          ...rest,
          ...originMaybe,
          page,
          pub_userRole: 'vendor',
          perPage: RESULT_PAGE_SIZE,
          include: ['author', 'images'],
          'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
          'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
          'fields.image': [
            'variants.scaled-small',
            'variants.scaled-medium',
            `variants.${variantPrefix}`,
            `variants.${variantPrefix}-2x`,
          ],
          ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
          ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
          'limit.images': 1,
        },
        config
      )
    ),
    dispatch(
      availableVendorCredits({
        page,
        pageSize: RESULT_PAGE_SIZE,
      })
    ),
    dispatch(fetchCustomerListings(config)),
    dispatch(isVendorCredits()),
    dispatch(fetchTransaction(userId)),
    dispatch(checkListingApproved()),
  ]);
};
