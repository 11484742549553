import React, { useEffect } from 'react';
import IconCard from '../IconCard/IconCard';
import css from './LeadCard.module.css';
import { FormattedMessage } from 'react-intl';
import { H3 } from '../Heading/Heading';
import Button from '../Button/Button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { handleSubmitInquiry } from '../../containers/ListingPage/ListingPage.shared';
import Collapsible from 'react-collapsible';
import Modal from '../Modal/Modal';
import InquiryForm from '../../containers/ListingPage/InquiryForm/InquiryForm';
import { createResourceLocatorString } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  HIRE_FIXED,
  HIRE_FIXED_CREDIT,
  PLANNING_TO_HIRE,
  PLANNING_TO_HIRE_CREDIT,
  R_NOT_SURE,
  R_NOT_SURE_CREDIT,
} from '../../util/types';
import NamedLink from '../NamedLink/NamedLink';
import { contactUsMessage } from '../../util/api';

const { UUID } = sdkTypes;

const LeadCard = props => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const routes = routeConfiguration;
  const [isInquiryModalOpen, setInquiryModal] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const {
    user,
    category,
    isValidCredits,
    creditType,
    isModalOpen,
    isAuthenticated,
    onSendInquiry,
    getListing,
    sendInquiryInProgress,
    sendInquiryError,
    userSearchId,
    currentUser,
    handleNotInterested,
    onVendorSpentCredits,
    matchedResponse,
    vendorSpentCredits,
    transactions,
    onCustomerListingView,
    isMobile = false,
  } = props;
  const {emailVerified,profile}= user?.author?.attributes || {};
  const {isPhoneNumberVerified } = profile?.publicData || {};
  const { title, publicData } = user.attributes || {};
  const { email } = user?.author?.attributes || {};
  const isCustomerView = user?.attributes?.publicData?.customerView == true;

  const maskEmail = email => {
    if (email?.includes('@')) {
      let [username, rest] = email.split('@');
      let [domain, extension] = rest.split('.');
      let maskedUsername = username[0] + '*'.repeat(username.length - 2) + username.slice(-1);
      let maskedDomain = domain[0] + '*'.repeat(domain.length - 2) + domain.slice(-1);
      let maskedEmail = maskedUsername + '@' + maskedDomain + '.' + extension;
      return maskedEmail;
    } else {
      return email;
    }
  };

  let maskedEmail = maskEmail(email);
  const {
    serviceType = [],
    location,
    phoneNumber,
    date,
    customerView,
    OtherEventBudget,
    OtherEventHiringDecision,
    OtherEventTypePlanning,
    OtherSpecificCulture,
    eventBudget,
    eventHiringDecision,
    eventTypePlanning,
    specificCulture,
  } = publicData;
  const eventDate = date ? date : "I'm not sure yet";
  const categoryName = serviceType
    .filter(item => category.includes(item.label))
    .map(filteredItem => filteredItem.label);

  const maskPhoneNumber = phoneNumber => {
    return phoneNumber?.substring(0, 3) + '*'.repeat(Math.max(0, phoneNumber?.length - 3));
  };
  const maskedPhoneNumber = maskPhoneNumber(phoneNumber);
  const listingId = user?.id;

  const enquireListingIds = transactions?.filter(
    item => item?.relationships?.listing?.data?.id?.uuid === listingId?.uuid
  );
  const alreadyTxId = enquireListingIds?.find(tx => tx?.id?.uuid);

  const handleSubmitInquiry = parameters => values => {
    const {
      history,
      params,
      user,
      onSendInquiry,
      routes,
      setInquiryModalOpen,
      alreadyTxId,
    } = parameters;
    const listing = user;
    const message = 'Inquiry about listing';
  
    if (!alreadyTxId?.id?.uuid) {
      onSendInquiry(listing, message)
        .then(txId => {
          setInquiryModalOpen(false);
          history.push(
            createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
          );
        })
        .catch(() => {});
    } else {
      history.push(
        createResourceLocatorString('OrderDetailsPage', routes, { id: alreadyTxId?.id?.uuid }, {})
      );
    }
  };

  const commonParams = { params: { id: listingId.uuid }, history, routes, alreadyTxId };
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    user,
    onSendInquiry,
    setInquiryModalOpen: setInquiryModal,
  });

  const creditDebit =
    eventHiringDecision && eventHiringDecision == R_NOT_SURE
      ? R_NOT_SURE_CREDIT
      : eventHiringDecision && eventHiringDecision == PLANNING_TO_HIRE
      ? PLANNING_TO_HIRE_CREDIT
      : eventHiringDecision && eventHiringDecision == HIRE_FIXED
      ? HIRE_FIXED_CREDIT
      : 10;

  const params = {
    userId: currentUser?.id?.uuid,
    type: 'DEBIT',
    credits: creditDebit,
    listingId: listingId?.uuid,
    name: user?.attributes?.title,
  };

  const creditInCurrentUser = currentUser?.attributes?.profile?.protectedData?.totalCredits || 0;
  const totalCredits =
    creditInCurrentUser && creditInCurrentUser > creditDebit
      ? creditInCurrentUser - creditDebit
      : creditInCurrentUser;

  const tempData =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.protectedData?.tempData;

  const isListingAvailableInTemp =
    tempData && tempData.length && tempData.filter(item => item.listingId === listingId?.uuid);
  const isVendorSpentCredit = isListingAvailableInTemp && isListingAvailableInTemp.length;
  const customerListingId = user?.id?.uuid;

  const checkCreditTransactions = (vendorSpentCredits, customerListingId) => {
    const alreadySpentCredit = vendorSpentCredits?.vendorCredits?.filter(
      item => item.listingId === customerListingId
    );
    const creditTransactions = alreadySpentCredit?.filter(item => Number(item.credits) > 0);

    return creditTransactions?.length > 0;
  };
  const result = checkCreditTransactions(vendorSpentCredits, customerListingId);

  const { firstName, lastName } = currentUser?.attributes?.profile;
  const vendorName = firstName + ' ' + lastName;

  return (
    <div className={css.learWrapper}>
      {isMobile ? (
        <button
          className={css.backListButton}
          onClick={e => {
            e.preventDefault();
            return history.push(createResourceLocatorString('SearchPage', routes, {}, {}));
          }}
        >
          <IconCard brand="backlist" />
          Back to list
        </button>
      ) : null}
      <div className={css.listItem}>
        <b>{title}</b>
      </div>
      <div className={css.listItem}>{categoryName.join(',')}</div>
      <div className={css.listItem}>{location.address ? location?.address : location?.search}</div>
      <div className={css.listItem}>
        <IconCard brand="email" /> &nbsp;
        {isVendorSpentCredit ? <span>{email}</span> : <span>{maskedEmail}</span>}
        {emailVerified ? <span className={css.verifiedText}><IconCard brand="verify"/><span>Verified</span></span>: null}
      </div>
      <div className={css.listItem}>
        <IconCard brand="phone" /> &nbsp;
        {isVendorSpentCredit ? <span>{phoneNumber}</span> : <span>{maskedPhoneNumber}</span>}
        {isPhoneNumberVerified ? <span className={css.verifiedText}><IconCard brand="verify"/><span>Verified</span></span>: null}
      </div>
      <div className={css.spentText}>{`You need to spent ${creditDebit} credits to contact ${title}`}</div>
      <div className={css.buttonsGroup}>
        <Button
          className={css.submitButton}
          onClick={() => {
            if (result) {
              setInquiryModal(true);
            } else if (isAuthenticated && isValidCredits >= creditDebit) {
              !result && onVendorSpentCredits(params, totalCredits);
              !isCustomerView &&
                contactUsMessage({
                  customerName: title,
                  vendorName: vendorName,
                  vendorcategory: categoryName,
                  customerId: email,
                  senderId: 'info@myfindor.com',
                  subject: 'MyFindor Support',
                  text: 'text',
                  spentCredit: 'spentCredit',
                });
              onCustomerListingView({ listingId: listingId.uuid });
              setInquiryModal(true);
            } else {
              setIsContactModalOpen(title);
            }
          }}
        >
          <FormattedMessage id="SearchPage.contactButton" />
          <span>{title}</span>
        </Button>
        <Button
          className={css.submitButton}
          onClick={async () => {
            await handleNotInterested(listingId.uuid);
            setTimeout(() => {
              const newSearchId =
                matchedResponse &&
                matchedResponse.length &&
                matchedResponse.length > 1 &&
                matchedResponse[1].id.uuid;
              if (newSearchId) {
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration,
                    {},
                    { userSearchId: newSearchId }
                  )
                );
              }
            }, 2000);
          }}
        >
          <FormattedMessage id="SearchPage.notInterstedButton" />
        </Button>
      </div>
      <div>
        <br />
        <hr />
        <H3> Details </H3>
        <div className={css.questionsDetails}>
          <div className={css.listItem}>
            <FormattedMessage id="SearchPage.eventPlanning" />{' '}
          </div>
          <span className={css.answerTextWithMargin}>
            {OtherEventTypePlanning ? OtherEventTypePlanning : eventTypePlanning}
          </span>
          <div className={css.listItem}>
            <FormattedMessage id="SearchPage.eventBudget" />
          </div>
          <span className={css.answerTextWithMargin}>
            {OtherEventBudget ? OtherEventBudget : eventBudget}
          </span>
          <div className={css.listItem}>
            <FormattedMessage id="SearchPage.hiringDecision" />
          </div>
          <span className={css.answerTextWithMargin}>
            {OtherEventHiringDecision ? OtherEventHiringDecision : eventHiringDecision}
          </span>
          <div className={css.listItem}>
            <FormattedMessage id="SearchPage.eventDate" />
          </div>
          <span className={css.answerTextWithMargin}>{eventDate}</span>
          <div className={css.listItem}>
            Are there specific cultural considerations or requirements that you are seeking for this
            event?
          </div>
          <span className={css.answerTextWithMargin}>
            {OtherSpecificCulture ? OtherSpecificCulture : specificCulture}
          </span>
          <div>
            {publicData?.serviceType
              ?.filter(st => category.includes(st.label))
              .map(st => {
                const questions = st.subCategoriesArray;
                return (
                  <ul className={css.questionList}>
                    {questions &&
                      questions.map(question => {
                        const questionValue = question.value;
                        const answerArray = Object.values(publicData?.serviceTypeValues);
                        const answerObject = answerArray.find(ans => ans[questionValue]);

                        const answer = answerObject ? answerObject[questionValue] : '';
                        const otherPresent =
                          answer &&
                          answer.includes('Other') &&
                          answerObject[questionValue + 'Other'];
                        return (
                          <li>
                            <span className={css.questionText}>{question.text}</span> <br />{' '}
                            <span className={css.answerText}>
                              {otherPresent ? otherPresent : answer}
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                );
              })}
          </div>
        </div>
      </div>
      {isInquiryModalOpen ? (
        <Modal
          id="ListingPage.inquiry"
          contentClassName={css.inquiryModalContent}
          isOpen={isInquiryModalOpen}
          onClose={() => [setInquiryModal(false)]}
          usePortal
          onManageDisableScrolling={() => {}}
        >
          <InquiryForm
            listingTitle={title}
            sendInquiryError={sendInquiryError}
            onSubmit={onSubmitInquiry}
            inProgress={sendInquiryInProgress}
            alreadyTxId={alreadyTxId}
          />
        </Modal>
      ) : null}
      <Modal
        id="VerifyDeletingPaymentMethod"
        isOpen={isContactModalOpen}
        onClose={() => [setIsContactModalOpen(false)]}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.creditWrapper}>
          <div className={css.creditHeading}>
            You need {creditDebit} credits to contact {isContactModalOpen}
          </div>
          <p>
            To get some credits, you need to buy a starter pack of credits (Enough for this lead +
            roughly another {creditDebit} leads)
          </p>
          <div>
            <div className={css.faqText}>
              <Collapsible trigger={'What are credits ?'}>
                {
                  'Credits are Myfindor online currency. If you see a job that you like and you want to get in contact with that customer, then you use credits to purchase their contact details (you will receive their personal phone number and email address). The amount of credits required to contact a customer varies depending on the potential value of the job .'
                }
              </Collapsible>
            </div>
            {/* <ul>
                <li>What are credits ?</li>
                <span></span>
              </ul> */}
          </div>
          <div className={css.creditPurchased}>
            You will use {creditDebit} purchased credits to contact {isContactModalOpen}
          </div>
          <div className={css.creditButton}>
            <NamedLink name="PurchaseCreditsPage" params={{ credit: creditDebit }}>
              Buy credits
            </NamedLink>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LeadCard;
